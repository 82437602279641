.Badge_crear_cuenta {
    background: #FFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    overflow: hidden;
    min-height: 300px;

    
    flex: 1 1 300px;
    width: 100%;
    min-width: 300px;
    max-width: 400px;

    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto 20px;
    padding: 0 0;
  }
  
  .Badge__header__crear_cuenta {
    width: 100%;
    height: 80px;

    background: #ffc2cd30;
    display: flex;
    justify-content: center;
    
    padding: 1rem 0;
    margin: 0;
  }

  .Badge__header-icon_crear_cuenta {
    border-radius: 20%;
    width: 50px;
    height: 50px;

    align-items: center;
  }
  
  .Badge__avatar-fondo-portada_crear_cuenta{
    display: flex;
    flex-direction: column;
    background: #FFF5E6;

    width: auto;
    height: auto;
  }

  div.contenedor-imagen-avatar {
    width: 160px;
    height: 160px;

    display: inline-block;
    position: relative;
    overflow: hidden;
  
    border-radius: 50%;
    margin: 1rem 0 1rem;
  }
  
  div.contenedor-imagen-avatar img {
    width: 165px;
    height: 165px;

    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Badge__section-name_crear_cuenta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    color: #415164;
  }

  .Badge__section-info_crear_cuenta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    color: #B4A8A4;
    background: #f4f4f7;
  }
  .Badge__formulario {
    margin: 20px;
  }

  .Badge__input {
    width: 100%;
    max-width: 270px;
  
    padding: 10px;
    display: block;
  
    border: solid;
    border-radius: 10px;
    border-width: 0.2px;
    border-color: #31313120;
  
    font-family: 'Nunito', 'Poppins';
    font-size: large;
    font-weight: light;
  }

  .Badge__boton-buscar {
    width: 170px;
    height: 30px;

    display: block;
    margin: 0 3rem;

    color: #fff;
    background: #22C7A490;

    border-radius: 10px;
    border: none;
  }
  
  .Badge__boton-buscar:hover {
    color: #fff;
    background-color: #058775;
  }
  
  .Badge__footer_crear_cuenta {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b618f;
    font-weight: bold;
    font-size: small;
    font-style: normal;

    text-decoration: none;
  }