@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Anton:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Source+Sans+3&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noticia+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&family=Noticia+Text&family=Roboto:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Patua+One&display=swap');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Nunito', 'Montserrat', Poppins;
  color: #5b5552;
  background-color: #f9fafc;
  overscroll-behavior: auto;

  animation: colorAnimationBody 3s linear alternate;
}

@keyframes colorAnimationBody {
  from { background-color: #D1DBFF; }
  to { background-color: #f9fafc; }
}

a {
  font-weight: bold;
  color: 	#7289da;
  text-decoration: none;
}

h1 {
  padding: 0.5rem 0;

  font-size: 340%;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0px;
    
  font-family: 'Montserrat';
  color: #ff084a;

  margin: 10px 0 0;
}

h2 {
  font-size: 200%;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0px;

  font-family: 'Montserrat';
  color: 	#7289da;
}

h3 {
  font-size: x-large;
  font-weight: bold;
  font-family: 'Montserrat';
  color: #ff084a;
}

h4 {
  font-size: large;
  font-weight: normal;
  font-family: 'Montserrat';
  color: #2c65c3;
}

h5 {
  font-size: large;
  font-weight: bold;
  font-family: 'Montserrat';

  margin: 0;
}

h6 {
  font-size: medium;
  font-weight: normal;
  font-family: 'Nunito', 'Poppins';

  margin: 0;
}

/* ------------------ */

/* Loader de carga del sitio */

.fondo-carga{
  opacity: 1;
  height: 100vh;
  display: flex;
  background-color:#D1DBFF;
}

.contenedor-carga{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-texto_fuente-bold {
  font-weight: normal;
  /* font-family: 'Montserrat', 'sans-serif'; */
  font-family: 'Patua One', cursive;
  color: #313131;
  font-size: 70px;
  margin: 0;
}

.loader-texto_fuente-light {
  font-weight: normal;
  font-size: 70px;
  /* font-family: 'Nunito', 'sans-serif'; */
  font-family: 'Comfortaa', cursive;
  color: #fd1205;
  margin: 4px 0 0;
}

.loader-texto_fuente-web {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: fixed;
  bottom: 8px;

  font-weight: normal;
  font-size: 20px;
  /* font-family: 'Nunito', 'sans-serif'; */
  font-family: 'Roboto', sans-serif;
  color: #313131;

  animation: colorAnimation 1s linear alternate;
  animation-iteration-count: infinite;
}

@keyframes colorAnimation {
  from { color: #2b547f; }
  to { color: #7E0902; }
}

.loader-circle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: fixed;
  left: calc(50% - 20px);
  bottom: 50px;
  

  border: 5px solid #D1DBFF; /* Light grey */
  border-top: 5px solid #2b547f; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1.4s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hide{
  display: none;
  overflow: hidden;
}

.ocultar-carga{
  overflow: hidden;

  height: 0vh;
  transition: height 1s;
}
.ocultar-fondo-carga{
  overflow: hidden;

  /* opacity: 0;
  transition: opacity 1s; */
}

/* ------------------ */

.hero {
  height: 340px;
  background: #f9fafc;
}

.imagen-portada {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  
  opacity: .50;
  -moz-opacity: .50;
  filter:alpha(opacity=50);

  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;
}

.formulario {

  display: block;
  min-width: 300px;
  height: 100%;

  margin: 16px 0;
}

.formulario-tarea {
  display: inline-flex;
  height: 100%;
  margin: 5px 0 10px;
}

.label-tarea {
  height: 100%;
  margin: 0 5px 0 25px;
}

.input-tarea {
  width: 100%;
  height: 120px;

  min-height: 120px;
  max-height: 500px;

  margin: 16px auto 16px;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  border-width: 2px;
  border-color: #ffc2cd50;

  font-family: 'Nunito', 'Poppins';
  font-size: medium;
  font-weight: lighter;
}

.span-normal {
  margin: auto 5px;
}

.span-texto-normal {
  
  min-height: 100px;

  font-size: larger;
  font-weight: 500;
  font-family: 'Nunito', 'Roboto';
  color: #585858;
  background: #ffffff00;
}

.span-centrado {
  font-size: xx-large;
  color: #fff;
  background-color:#e70e0490;
  border-radius: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 300px;
  height: 70px;

  padding: 4rem 1rem;
  margin: 0px 0px 50px;
}

.span-centrado-light {
  font-size: x-large;
  font-weight: bold;
  font-family: 'Montserrat';
  color: #e70e0495;
  background-color:#ffc2cd20;
  border-radius: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 300px;
  height: 70px;

  padding: 3rem 2rem;
  margin: -20px 0 50px;
}

.span-filtro-dia {
  font-weight: bold;
  font-size: smaller;
  color: #555555;

  border: #55555550;
  border-width: 2px;
  border-style: solid;
  border-radius: 25px;
  background: #ffffff10;
  

  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 5px;
  padding: 5px 10px;

  min-width: 180px;
  max-width: fit-content;
  max-height: 35px;
}

.link-normal{
  text-decoration: none;
  align-items: center;
  font-weight: bolder;
  font-family: 'Montserrat';
  color: #1a94ff;

  padding-top: 0.7rem;
}

.link-normal:hover{
  text-decoration: none;
  color: #065397;
}

.link-simple {
  font-size: small;
  color: #7289da;
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  
  margin: 0px 0px 20px;
  font-family: 'Nunito', 'Poppins';
}
.link-simple:hover {
  color: #415164;
}

.link-fans {
  font-size: large;
  color: #7289da;
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  
  margin: -10px 0px 20px;
  font-family: 'Nunito', 'Poppins';
}
.link-fans:hover {
  color: #415164;
  cursor: pointer;
}

a.link-unstyled {
  color: inherit;
}

a.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}

.link-bold {
  color: #415164;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Nunito', 'Poppins';
}

.icon {
  margin: 50px 10px 0;
  padding: 2px;
  width: 30px;
  height: 30px;
}

.icon:hover {
  background-color: #ff885e40;
  border-radius: 4px;
  cursor: pointer;
}

.icon-tarjeta {
  margin: 0.7rem 0;
  padding: 2px;

  width: 20px;
  height: 20px;
}

.icon-tarjeta:hover {
  background-color: #ff885e40;
  border-radius: 4px;
  cursor: pointer;
}

.icon-footer {
  margin: 0.7rem 0;
  padding: 2px;

  width: 25px;
  height: 25px;
}

.icon-footer:hover {
  background-color: #ff885e40;
  border-radius: 4px;
  cursor: pointer;
}

.icon-siguiendo {
  margin: 0.4rem;

  width: 16px;
  height: 16px;
}

.icon-siguiendo:hover {
  background-color: #c2ffd170;
  border-radius: 4px;
  cursor: pointer;
}

.icon-verificado {
  width: 30px;
  height: 30px;

  margin: 16px 16px 0px 0px;
}

.icon-verificado:hover {
  background-color: #c2ffd170;
  border-radius: 4px;
  cursor: pointer;
}

.icon-estado-tarea {
  width: 27px;
  height: 27px;

  margin: 16px;
}

.icon-estado-tarea:hover {
  cursor: pointer;
}

.icon-editar {
  width: 27px;
  height: 27px;
  
  margin: 16px;
}

.icon-editar:hover {
  cursor: pointer;
}

.icon-actualizar {
  width: 27px;
  height: 27px;
  
  margin: 20px 16px 16px;
}

.icon-actualizar:hover {
  cursor: pointer;
}

.btn {
  color: #fff;
  background-color: #1d9bf0;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 300px;

  margin: 1rem;
}

.btn:hover {
  color: #d2e6ff;
  background-color: #062750;
  border-color: #d2e6ff;
  border-radius: 10px;
}

.btn.btn-primary {
  color: #fff;
  background-color: #22C7A4;
  border-color: #22C7A4;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 300px;
  margin: 20px auto;

  min-width: 180px;
  max-width: 300px;
  max-height: 50px;
}

.btn.btn-primary:hover {
  color: #fff;
  background-color: #058775;
}

.btn.btn-secondary {
  color: #fff;
  background-color: #0767dd;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 300px;

  margin: 1rem;
}

.btn.btn-secondary:hover {
  color: #d2e6ff;
  background-color: #062750;
  border-color: #d2e6ff;
  border-radius: 10px;
}

.btn.btn-inicio {
  color: #fff;
  background-color:#e70e04;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  min-width: 180px;
  max-width: 300px;
  max-height: 50px;
}

.btn.btn-inicio:hover {
  color: 	#fff;
  background-color: #7289da;
}

.btn.btn-login {
  color: #fff;
  background-color:#e70e04;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  min-width: 180px;
  max-width: 300px;
  max-height: 50px;

}

.btn.btn-login:hover {
  color: 	#fff;
  background-color: #7289da;
  border-radius: 10px;
}

.btn.btn-cerrar-sesion {
  color: #fff;
  background-color:#e70e04;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;

  width: 100%;
  min-width: 100px;
  max-width: 300px;
  max-height: 50px;

  flex: 1 1 50px;
}

.btn.btn-cerrar-sesion:hover {
  background-color: #7289da;
}

.btn.btn-filtro-dia {
  color: #3c7b2f;
  background-color:#c6ffb1;
  border-radius: 20px;

  

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 5px;

  min-width: 140px;
  max-width: fit-content;
  max-height: 35px;

  font-size: medium;
  font-family: 'Source Sans 3';
}

.btn.btn-filtro-dia:hover {
  color: 	#fff;
  background-color: #3c7b2f;
}

.btn.btn-actualizar-tarea {
  color: #000;
  background-color:#e7e7e750;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;

  min-width: 180px;
  max-width: 300px;
  max-height: 35px;
  
  font-family: 'Montserrat';
}

.btn.btn-actualizar-tarea:hover {
  color: 	#0071e3;
  background-color: #fff;
}

.btn.btn-seguir-usuario {
  color: #fff;
  background-color:#7289da;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;

  width: 100%;
  min-width: 100px;
  max-width: 300px;
  max-height: 50px;

  flex: 1 1 50px;

}

.btn.btn-seguir-usuario:hover {
  background-color: #7289da70;
}

.btn.btn-cuenta-encontrada {
  color: #fff;
  background-color:#7289da;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;

  width: 100%;
  min-width: 100px;
  max-width: 300px;
  max-height: 50px;

  flex: 1 1 50px;

}

.btn.btn-cuenta-encontrada:hover {
  background-color: #7289da70;
}

.btn.btn-generar-pdf {
  color: #fff;
  background-color:#0071e340;
  border-radius: 20px;
  margin: 10px;

  width: 100%;
  min-width: 120px;
  max-width: 180px;

  max-height: 50px;

  flex: 1 1 50px;
}

.btn.btn-generar-pdf:hover {
  background-color: #0071e3;
  max-height: 50px;
}

.btn.btn-escribir-comentario {
  color: #fff;
  background-color:#0071e330;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;

  width: 100%;
  min-width: 120px;
  max-width: 200px;
  max-height: 50px;

  flex: 1 1 50px;
}

.btn.btn-escribir-comentario:hover {
  background-color: #0071e3;
}

.wrapper {
  position: relative;
}
.wrapper :is(.btn, .link) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: rgb(218, 36, 36);
  color: #151515;
  border-radius: 50%;
}
.btn-para-compartir {
  position: absolute;
  border: none;
  cursor: pointer;
  z-index: 10;
  transition: outline .25s;
}
.active .btn-para-compartir,
.btn-para-compartir:hover{
  outline: 5px solid #bb93fc;
  outline-offset: -4px;
}
.item{
  position: absolute;
  list-style: none;
  transition: transform .5s;
  transition-delay: var(--d);
}
.wrapper.active .item:nth-child(1) {
  transform: translateX (-65px);
}
.wrapper.active .item:nth-child(2) {
  transform: translateY (-65px);
}
.wrapper.active item:nth-child(3) {
  transform: translateX (65px);
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: flex;
}


/* Botón de menú (Dropdown) */
.menu-desplegable-compartir {
  width: auto;
  height: auto;

  color: #fff;
  background-color:#7289da;
  border: none;
  border-radius: 10px;

  justify-content: center;
  align-items: center;
  align-content: center;

  margin: auto;

  padding: 8px 16px;
  font-size: 16px;
}

/* Hover & focus */
.menu-desplegable-compartir:hover, .menu-desplegable-compartir:focus {
  background-color: #7289da70;
}



/* Dropdown Content (Hidden by Default) */
.opciones-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;

  overflow: auto;
  
  height: auto;
  max-height: 300px;
  
  width: 50%;
  min-width: max-content;

  left: 50%;
  transform: translateX(-50%);

  bottom: 50px;
}

/* Links inside the dropdown */
.opciones-menu button {
  color: black;
  background: white;

  width: 100%;
  border: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  text-align: left;

  font-size: medium;
  cursor: pointer;
}

/* Hover & focus */
.opciones-menu button:hover {
  color: white;
  background: #0071e3;
}



/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

/* Tamaños en Pantalla */

.header-contenido-portada {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  min-width: 100%;
  
  margin: -270px auto 0;
  padding: 0 5%;
}

.header-pantalla-completa {
  width: 100%;
  min-width: fit-content;
  
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  
  padding: 0 12%;
}

.header-media-pantalla {
  width: 50%;
  height: 100%;
  
  min-height: 300px;
  min-width: 40%;
  
  display: block;
  justify-content: center;
  align-items: center;
  margin: 20px;

  padding: 5% 0;
  
  flex: 1 1 300px;
}

.header-media-pantalla-blanca {
  width: 50%;
  height: 100%;
  
  min-height: 300px;
  background: #ffffff;

  display: block;
  justify-content: center;
  align-items: center;
  margin: 5% 0;

  padding: 2rem 3rem;

  flex: 1 1 400px;
}

.header-un-tercio-pantalla {
  width: 33%;
  height: 100%;
  
  max-width: 500px;
  min-height: 300px;
  
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px;

  padding: 2rem 2rem 0;
  
  flex: 1 1 450px;
}

.header-dos-tercios-pantalla {
  width: 67%;
  height: 100%;

  min-height: 350px;
  
  display: block;
  justify-content: center;
  align-items: center;
  margin: 10px auto 40px;

  padding: 2rem 1rem;
  
  flex: 1 1 500px;
}

.dos-tercios-pantalla-comentarios {
  width: 67%;
  max-width: 400px;
  
  display: contents;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px;

  padding: 2rem 1rem 0;
  
  flex: 1 1 300px;
}

/* Imagen de Portada */

.header-portada {
  height: 50vh;
  background: #f9fafc;
  filter: drop-shadow(0px 1px 1px #31313120);
}

.imagen-portada {
  height: 100%;
  width: 100%;
  object-fit: cover;
  
  opacity: 0.20;
  -moz-opacity: 0.20;
  filter:alpha(opacity=20);
}

.header-contenido-portada {
  display: block;
  flex-wrap: wrap;
  align-items: center;
  justify-content: stretch;
  
  margin: -25vh auto 0;
  padding: 0 5%;

  filter: contrast(100%);
}

/* Divider */
.divider {    
  width: 25%;
  height: 100%;
  margin: 40px auto;

  border-color: transparent;
  border-bottom: #2f2f2f30;
  border-width: 1px;
  border-style: solid;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-naranja {  
  margin: 0 25%;
  margin-block: 2.5rem;
  border: none;
  border-top: 1px solid #ff895e;
}

.divider-footer {  
  margin: 0 25%;
  margin-block: 2.5rem;
  border: none;
  border-top: 1px solid #ff885eb9;
}

.visor-pdf-pantalla-completa {
  width: 100%;
  height: 100%;
  min-height: 400px;

  /* padding: 0 5%; */
  padding: 0;
  margin-top: 16px;
}

.filtro-pantalla-completa {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;

  background: #e7e7e750;
  border-radius: 20px;

  padding: 0 10px;
  margin: 0 auto 40px;
}

.ocultar-vista {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

input.ocultar-vista:focus + label {
  outline: thin dotted;
}

input.ocultar-vista:focus-within + label {
  outline: thin dotted;
}

div.imagen-avatar {
  width: 130px;
  height: 130px;

  border-radius: 50%;

  margin-top: -70px;
  margin-bottom: 20px;

  display: inline-block;
  position: relative;
  overflow: hidden;
}

div.imagen-avatar img {
  width: 135px;
  height: 135px;

  object-fit: cover;
  -o-object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.imagen-avatar-mini {
  width: 90px;
  height: 90px;

  border-radius: 50%;

  margin-top: -55px;
  margin-bottom: 20px;

  display: inline-block;
  position: relative;
  overflow: hidden;
}

div.imagen-avatar-mini img {
  width: 95px;
  height: 95px;

  object-fit: cover;
  -o-object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.snackbar {
  position: fixed;

  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;

  background-color: #6b2c11;
  color: white;

  display: flex;
  align-items: center;

  width: min(100%, 400px);

  padding: 0.5rem 0.7rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/* Aquí van los estilos para escritorio */

/* Aquí van los estilos para tabletas grandes */
@media screen and (max-width: 991px){
  .span-filtro-dia {  
    margin: 10px 5px;
    padding: 5px 10px;
  
    min-width: 85%;
  }
}

/* Aquí van los estilos para tabletas medianas */
@media screen and (max-width: 767px){}

/* Aquí van los estilos para smartphones */
@media screen and (max-width: 479px){

  /* loader */
  .loader-texto_fuente-bold {
    font-size: 40px;
  }

  .loader-texto_fuente-light {
    font-size: 40px;
  }

  .loader-texto_fuente-web {
    font-size: 16px;
  }

  /* ---------- */

  body {
    font-size: 17px;
  }

  h1 {  
    font-size: 200%;
    margin: 10px 0 0;
    padding: 0 0.5rem;
    line-height: 1;
  }
  
  h2 {
    font-size: 140%;
    line-height: 30px;
  }
  
  h3 {
    font-size: larger;
  }

  /* Divisores principales */

  .header-pantalla-completa {
    /* padding: 0 7%; */
    padding: 0;
    min-width: auto;
  }
  
  .header-media-pantalla {
    min-width: 100%;
    max-width: 100%;

    min-height: 300px;

    margin: 2% 0;
    padding: 5% 0;
    
    flex: 1 1 400px;
  }
  
  .header-media-pantalla-blanca {
    width: 100%;
  
    background: #ffffff00;
    border-radius: 10px;
    
    padding: 2rem 0;
    margin: 1rem 0;
  
    flex: 1 1 400px;
  }

  .visor-pdf-pantalla-completa {
    min-width: auto;
    min-height: 400px;
    /* background: #05877665; */
    /* background: #e7e7e750; */
    
    margin:  0;
    padding: 4px 4px;
  }
  
  .filtro-pantalla-completa {
    min-width: auto;

    padding: 0 16%;
    margin: 0 8px 40px;
  }

  /* Imagen de Portada */

  .header-portada {
    height: 50vh;
    background: #f9fafc;
    filter: drop-shadow(0px 1px 1px #31313120);
  }

  .imagen-portada {
    height: 100%;
    width: 100%;
    object-fit: cover;
    
    opacity: 0.20;
    -moz-opacity: 0.20;
    filter:alpha(opacity=20);
  }

  .header-contenido-portada {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
    
    margin: -20vh auto 0;
    padding: 0 5%;

    filter: contrast(100%);
  }

  /* Para compartir como imagen */

  .perfilParaTwitter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

}