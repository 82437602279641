.BadgeSiguiendo {
    background: #FFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    overflow: hidden;

    flex: 1 1 300px;
    width: 100%;
    min-width: 200px;
    max-width: 250px;

    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
  }
  
  .BadgeSiguiendo__header {
    width: 100%;
    height: 80px;
    background: #c2ffd150;
    display: flex;
    justify-content: flex-end;
  }

  .BadgeSiguiendo__header-icon {
    border-radius: 20%;
    width: 50px;
    height: 50px;

    align-items: center;
  }
  
  .BadgeSiguiendo__avatar-fondo-portada{
    display: flex;
    flex-direction: column;
    background: #FFF5E6;

    width: auto;
    height: auto;
  }


  .BadgeSiguiendo__avatar {
    border-radius: 50%;

    margin-top: -70px;
    margin-bottom: 1.5rem;

    object-fit: cover;
    width: 140px;
    height: 140px;
  }

  .BadgeSiguiendo__section-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    color: #415164;
  }

  .BadgeSiguiendo__section-biografia {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 10px;
    color: #756e6b;
    background: #fcfcff;
    border-radius: 10px;

    width: 90%;
  }

  .BadgeSiguiendo__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0.5rem 0 0.2rem;
  }
  
  .BadgeSiguiendo__paginaWeb {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b618f;
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    background-color: transparent;
    border: transparent;

    margin: 0 0 1rem 0;
  }

  .BadgeSiguiendo__paginaWeb:hover {
    color: #7289da;
    text-decoration: underline;
  }