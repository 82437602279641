.footer {
  padding-block: 4rem 1rem;

  color: #5b5552d7;
  font-size: large;

  background: #f7f7f7;
  filter: drop-shadow(0px 0px 1px #31313140);
}

.footer__container {
  max-width: 116rem;
  margin: auto;
  padding: 0 1.5rem;
  overflow: none;
  resize: horizontal;
}

.footer__top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
  row-gap: 2rem;
}

.company {
  padding-right: 0rem;
}
.company__logo {
  color: #5b5553f0;
}
.company__description {
  font-size: smaller;
}
.copyright {
  margin-top: auto;
  font-size: small;
}

.footer__column {
  margin: 0 auto;
}

.footer__title {
  margin-bottom: 2rem;

  color: #3a00e0;
  font-weight: bold;
  font-family: 'Montserrat';
  font-size: x-large;
}

.footer__list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.footer__list-icon {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
}

.footer__list-link {
  text-decoration: none;
  color: inherit;
  transition: color .25s;

  font-family: 'Source Sans 3';
  font-weight: normal;
  font-size: medium;
}

.footer__list-link:hover {
  color: #ff885e;
}

.footer__divider {
  margin-block: 2.5rem;
  border: none;
  border-top: 1px solid #ff885eb9;
}

/* Aquí van los estilos para tabletas medianas */
@media screen and (max-width: 767px){

  .footer {
    padding-block: 5rem;
    font-size: medium;
  }
  
  .footer__top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    row-gap: 2rem;
  }

  .footer__column {
    margin: 0 1rem;
  }

  .footer__title {
    margin-bottom: 1rem;
    font-size: large;
  }

  .footer__list {
    gap: 1rem;
  }

}

/* Aquí van los estilos para smartphones */
@media screen and (max-width: 479px){

  .footer {
    padding-block: 4rem 3rem;
  }
  
  .footer__top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    row-gap: 2.5rem;
  }

  .footer__column {
    margin: 0 1rem;
  }

  .footer__title {
    margin-bottom: 1rem;
    font-size: medium;
  }

  .footer__list {
    gap: 0.5rem;
  }

}