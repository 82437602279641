.Navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    width: 100%;
    height: 70px;

    padding: 0 16px;
    background-color: #b3c4ff;
    text-decoration: none;

    filter: drop-shadow(0px 1px 1px #31313140);
    z-index: 4;
    
    position: sticky;
    top: 0;
  }

  /* Fondo gris (para menú de sidebar) */
  .overlay {
    width: 100%;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  .overlay.open {
    opacity: 1;
    visibility: visible;
  }

  .overlay-izquierdo {
    width: 100%;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  .overlay-izquierdo.open {
    opacity: 1;
    visibility: visible;
  }

  /* Caja del Menú (para menú de sidebar) */
  .modal-derecho {
    width: 0;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    right: 0;

    background: white;
    transform: translate(0);
    transition: 0.3s;
  }
  .modal-derecho.open {
    transform: translate(-250px);
  }

  .modal-izquierdo {
    width: 0;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    left: 0;

    background: white;
    transform: translate(0);
    transition: 0.3s;
  }
  .modal-izquierdo.open {
    transform: translate(250px);
  }

  /* Caja de íconos */
  .lista-iconos {
    display: flex;
    align-content: center;
    align-items: center;
  }
  
  /* Íconos */
  .navbar-icon {
    width: 40px;
    height: 40px;

    display: grid;
    place-items: center;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    margin: 0 8px;
    padding: 7px;
  }
  .navbar-icon:hover {
    opacity: 1;
    background: #00000026;
  }

  /* Mantiene oculto el ícono de menú */
  .navbar-icon-oculto {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  /* Imagen de usuario en formato de un icono */
  div.navbar-icon-imagen {
    width: 40px;
    height: 40px;
    cursor: pointer;

    display: inline-block;
    position: relative;
    overflow: hidden;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    margin: 0 8px;
    padding: 7px;
  }
  div.navbar-icon-imagen:hover {
    opacity: 1;
    background: #00000026;
  }

  div.navbar-icon-imagen img {
    width: 43px;
    height: 43px;
    object-fit: cover;
    -o-object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Logo de la página */
  .Navbar__brand {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .Navbar__brand:hover {
    background-color: #a5b8fdbc;
    border-radius: 10px;
    
    font-style: 'text';
    text-decoration: none !important;        
  }

  .Navbar__brand-oculto {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .Navbar__brand-oculto:hover {
    background-color: #a5b8fd20;
    border-radius: 10px;
    
    font-style: 'text';
    text-decoration: none !important;        
  }

  /* Nombre de la página */
  .Navbar__fuente-bold {
    font-weight: bolder;
    font-size: x-large;
    font-family: 'Segoe UI', 'Nunito';
    color: #313131;
    cursor: pointer;
  }
  .Navbar__fuente-light {
    font-weight: bold;
    font-size: 28px;
    font-family: 'Nunito';
    color: #fd1205;
    padding: 0.2rem 0 0;
    
    display: inline-flex;
    place-items: center;
  }
  
  /* Logo de la página */
  .Navbar__brand-logo {
    margin: 5px 0;
    margin-right: 7px;
    width: 35px;
    height: 35px;
  }

  /* Texto: Menú */
  .texto-menu{
    text-decoration: none;
    align-items: center;
    font-size: medium;
    font-family: 'Montserrat';
    color: #313131;
  }
  .texto-menu:hover{
    text-decoration: none;
    align-items: center;
    color: #fd1205;
  }

  /* Link predeterminado de la página */
  .link-menu{
    text-decoration: none;
    font-weight: bolder;
    font-size: large;
    font-family: 'Montserrat';
    color: #313131;

    margin: 1rem 8px;
    padding: 0.4rem 0.7rem;
  }
  .link-menu:hover{
    color: #fff;

    border-radius: 10px;
    background: #7289da;
  }

  /* Link principal de la página */
  .link-menu-principal{
    text-decoration: none;
    font-weight: bolder;
    font-size: medium;
    font-family: 'Montserrat';
    color: #fff;

    margin: 1rem 8px;
    padding: 0.4rem 0.7rem;

    border-radius: 10px;
    background: #7289da;
  }
  .link-menu-principal:hover{
    color: #313131;
    background: #a5b8fd70;
  }




  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

    .Navbar {
      min-height: 50px;
      padding: 0.2rem 1rem;
    }

    /* Se muestra el ícono */
    .navbar-icon-oculto {
      width: 40px;
      height: 40px;
      visibility: visible;

      display: grid;
      place-items: center;
      
      background: #E4E6EB;
      border: none;
      
      border-radius: 50%;
      opacity: 0.75;
      transition: 0.3s;
      margin: 0 8px;
      padding: 7px;
    }
    .navbar-icon-oculto:hover {
      opacity: 1;
      background: #00000026;
    }

    .Navbar__brand-oculto {
      display: none;
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    /* Se ocultan los botones del menú */
    .link-menu {
      display: none;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .link-menu-principal {
      visibility: hidden;
      width: 0;
      height: 0;
    }

  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    .Navbar {
      max-height: 60px;
      padding: 0.2rem 1rem;
    }

  }