.BadgeMain__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0.5rem 2rem;
  margin: 0 0;
  background: #f7f7f7;
  
  }

  .BadgeMain__hero {
    display:flex;
    align-items: center;

    height: 100%;
    height: 100%;
  }
  
  .BadgeMain__hero>.Badges__container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;

    background: #f9fafc;
  }

  .BadgeMain__fondo-portada{
    width: 100%;
    height: 100%;
    background: #f9fafc;

    padding-bottom: 1rem;
  }

  .BadgeMain__fuente-portada-titulo {
    display: flex;
    width: 100%;

    font-size: 50px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0px;
    
    font-family: 'Montserrat';
    color: 	#e70e04;
  }

  .BadgeMain__fuente-portada-descripcion {
    display:flex;
    width: 100%;

    font-size: 30px;
    line-height: 35px;
    font-weight: 500;
    letter-spacing: 0px;
    font-family: 'Montserrat';

    color: 		#7289da;
  }

  .BadgeMain__textos {
    color: #313131;
    font-size: large;
    font-family: 'Source Sans 3';
  }